<template>
  <VExpansionPanel>
    <VExpansionPanelHeader>
      <template v-if="item.name && item.name.trim() && item.name.trim().length">
        {{ item.name }}
      </template>
      <template v-else> Endpoint #{{ index }} </template>
      <VSpacer />
      <div class="d-flex flex-row justify-end pr-5" v-if="!readonly">
        <VEditDialog large @save="deleteMe" save-text="Yes">
          <div class="d-flex">
            <VBtn class="ml-2" fab small icon color="error" elevation="0" :loading="deleting">
              <VIcon small class="">fa-trash</VIcon>
            </VBtn>
          </div>
          <template v-slot:input>
            <p class="mt-4 mb-0">
              Are you sure to <b class="error--text">delete</b> this endpoint?
            </p>
          </template>
        </VEditDialog>
      </div>
    </VExpansionPanelHeader>
    <VExpansionPanelContent>
      <VTextField
        outlined
        autofocus
        dense
        label="Name"
        v-model="name"
        :readonly="readonly"
        @input="updateProp('name')"
      />
      <VTextField
        outlined
        dense
        label="Description"
        v-model="description"
        :readonly="readonly"
        @input="updateProp('description')"
      />
      <div class="d-flex flex-grow-1">
        <VSelect
          :style="{ maxWidth: '130px' }"
          outlined
          dense
          label="Method"
          :items="methodsList"
          v-model="method"
          :readonly="readonly"
          @input="updateProp('method')"
        />
        <VTextField
          outlined
          dense
          label="Endpoint"
          v-model="endpoint"
          :readonly="readonly"
          @input="updateProp('endpoint')"
        />
      </div>
      <VTextField
        outlined
        dense
        label="Key"
        v-model="key"
        persistent-hint
        :hint="`Endpoint will be represent at {HOST}/integration/${key}`"
        :readonly="readonly"
        @input="updateProp('key')"
      />
      <h4 class="mb-3">Inputs</h4>
      <VExpansionPanels class="" multiple v-model="panels">
        <InputItem
          v-for="(input, index) in item.inputs"
          :index="index"
          :key="`${index}`"
          :item="input"
          :withAction="item && item.id > 0"
          :endpointId="item.id"
          :integrationId="integrationId"
          :readonly="readonly"
          @delete="deleteInput"
          @save="saveInput"
        />
      </VExpansionPanels>
      <VBtn
        color="info"
        class="text-normal mt-4"
        type="button"
        @click="addDummyInput"
        v-if="!readonly"
      >
        <VIcon class="mr-2" dense>fa-plus-circle</VIcon>
        <span>Add Input</span>
      </VBtn>
    </VExpansionPanelContent>
    <VExpansionPanelContent class="" v-if="withAction && !readonly">
      <div class="d-flex flex-row justify-end">
        <VBtn
          color="warning"
          class="text-normal mr-4"
          type="button"
          @click="saveMe"
          :loading="saving"
          :disabled="!dataHasChanged && !isNew"
        >
          <VIcon class="mr-2" dense>fa-save</VIcon>
          <span>Save</span>
        </VBtn>
        <!-- <VBtn type="button">Cancel</VBtn> -->
      </div>
    </VExpansionPanelContent>
  </VExpansionPanel>
</template>

<script>
import { getMethodColor } from "@/utils/vuetify";
import InputItem from "./InputItem.vue";
import { mapActions, mapMutations } from 'vuex';
export default {
  components: { InputItem },
  name: "EndpointItem",
  props: {
    item: Object,
    index: Number,
    withAction: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    integrationId: {
      type: [Number, String],
      default: 0
    }
  },
  data: () => ({
    panels: [],
    name: "",
    description: "",
    method: "",
    endpoint: "",
    key: "",
    deleting: false,
    saving: false,
  }),
  computed: {
    methodsList() {
      return ["GET", "POST", "PUT", "DELETE", "PATCH", "HEAD"];
    },
    isNew() {
      if (!this.item || !this.item.id) {
        return true;
      }
      return false;
    },
    dataHasChanged() {
      if (!this.item) {
        return true;
      }
      const { key, name, description, method, endpoint } = this.item;
      return (
        this.key != key ||
        this.name != name ||
        this.description != description ||
        this.method != method ||
        this.endpoint != endpoint
      );
    }
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.setValue(val);
      }
    }
  },
  created() {
    this.setValue(this.item);
  },
  methods: {
    ...mapMutations({
      OpenError: "OpenError",
    }),
    ...mapActions("integration", {
      dispatchCreateEndpoint: "createEndpoint",
      dispatchUpdateEndpoint: "updateEndpoint",
      dispatchDeleteEndpoint: "deleteEndpoint",
    }),
    getMethodColor,
    updateProp(key) {
      if (this.isNew) {
        this.item[key] = this[key];
      }
    },
    setValue(val) {
      this.name = val.name;
      this.description = val.description;
      this.method = val.method;
      this.endpoint = val.endpoint;
      this.key = val.key;
    },
    addDummyInput() {
      if (!this.item.inputs) {
        this.item.inputs = [];
      }
      this.panels.push(this.item.inputs.length);
      this.item.inputs.push({
        key: "",
        defaultValue: "",
        position: "body",
        inputType: "manual"
      });
    },
    async deleteMe() {
      if (this.readonly) {
        return;
      }
      if (!this.isNew) {
        try {
          this.deleting = true;
          await this.dispatchDeleteEndpoint({
            id: this.integrationId,
            endpointId: this.item.id
          })
        } catch (error) {
          this.OpenError({
            message: `Cannot delete enpoint due to [${error}]`
          });
          this.deleting = false;
          return;
        }
      }
      this.deleting = false;
      this.$emit("delete", { item: this.item, index: this.index });
    },
    async saveMe() {
      if (this.readonly || !this.withAction || (!this.dataHasChanged && !this.isNew)) {
        return;
      }
      let submitFunc = this.dispatchCreateEndpoint;
      let params = {
        id: this.integrationId,
        data: {
          name: this.name,
          description: this.description,
          method: this.method,
          endpoint: this.endpoint,
          key: this.key,
          inputs: [...(this.item.inputs || [])]
        }
      };
      if (!this.isNew) {
        submitFunc = this.dispatchUpdateEndpoint;
        delete params.data.inputs;
        params.endpointId = this.item.id;
      }
      let outputData = {};
      try {
        this.saving = true
        outputData = await submitFunc(params);
      } catch (err) {
        this.OpenError({
          message: `Cannot save enpoint due to [${err}]`
        });
        this.saving = false;
        return;
      }

      this.saving = false;

      const data = {
        ...this.item,
        ...outputData,
        name: this.name,
        description: this.description,
        method: this.method,
        endpoint: this.endpoint,
        key: this.key,
      };
      this.$emit("save", data, this.index);
    },

    // inputs
    deleteInput({ item, index }) {
      this.item.inputs.splice(index, 1);
      const foundAt = this.panels.findIndex(x => x == index);
      if (foundAt > -1) {
        this.panels.splice(foundAt, 1);
      }
    },
    saveInput(data, index) {
      const copyData = JSON.parse(JSON.stringify(data));
      console.log(data);

      Object.keys(copyData).forEach(key => {
        // this.item.inputs[index][key] = copyData[key];
        this.$set(this.item.inputs[index], key, copyData[key]);
      })

      const emitData = {
        endpoint: {
          id: this.item.id,
          index: this.index,
        },
        input: {
          data,
          index
        }
      }
      this.$emit("save:input", emitData);
    }
  }
};
</script>

<style></style>
