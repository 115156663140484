<template>
  <VExpansionPanel>
    <VExpansionPanelHeader>
      <template v-if="key && key.trim() && key.trim().length">
        {{ key }}
      </template>
      <template v-else>Input #{{ index }}</template>
      <VSpacer />
      <div class="d-flex flex-row justify-end pr-5" v-if="!readonly">
        <VEditDialog large @save="deleteMe" save-text="Yes">
          <div class="d-flex">
            <VBtn class="ml-2" fab small icon color="error" elevation="0" :loading="deleting">
              <VIcon small class="">fa-trash</VIcon>
            </VBtn>
          </div>
          <template v-slot:input>
            <p class="mt-4 mb-0">
              Are you sure to <b class="error--text">delete</b> this input?
            </p>
          </template>
        </VEditDialog>
      </div>
    </VExpansionPanelHeader>
    <VExpansionPanelContent>
      <VRow>
        <VCol cols="6">
          <VSelect
            label="Input Type"
            outlined
            dense
            :items="inputTypeOptions"
            v-model="inputType"
            :readonly="readonly"
            @input="updateProp('inputType')"
          />
        </VCol>
        <VCol cols="6">
          <VTextField
            label="Key"
            autofocus
            outlined
            dense
            v-model="key"
            :readonly="readonly"
            @input="updateProp('key')"
          />
        </VCol>
        <VCol cols="6">
          <VTextField
            label="Default value"
            outlined
            dense
            v-model="defaultValue"
            :readonly="readonly"
            @input="updateProp('defaultValue')"
          />
        </VCol>
        <VCol cols="6">
          <VSelect
            label="Position"
            outlined
            dense
            :items="positionOptions"
            v-model="position"
            :readonly="readonly"
            @input="updateProp('position')"
          />
        </VCol>
      </VRow>
    </VExpansionPanelContent>
    <VExpansionPanelContent class="" v-if="withAction && !readonly">
      <div class="d-flex flex-row justify-end">
        <VBtn
          color="warning"
          class="text-normal mr-4"
          type="button"
          @click="saveMe"
          :disabled="!dataHasChanged && !isNew"
          :loading="saving"
        >
          <VIcon class="mr-2" dense>fa-save</VIcon>
          <span>Save</span>
        </VBtn>
        <!-- <VBtn type="button">Cancel</VBtn> -->
      </div>
    </VExpansionPanelContent>
  </VExpansionPanel>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
  name: "InputItem",
  props: {
    item: Object,
    index: Number,
    withAction: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    integrationId: {
      type: [Number, String],
      default: 0
    },
    endpointId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      saving: false,
      deleting: false,
      key: "",
      defaultValue: "",
      position: "",
      inputType: ""
    };
  },
  computed: {
    positionOptions() {
      return [
        {
          text: "Body",
          value: "body",
        },
        {
          text: "Header",
          value: "header",
        },
        {
          text: "Query",
          value: "query",
        },
      ]
    },
    inputTypeOptions() {
      return [
        {
          text: "Manual (User Input)",
          value: "manual",
        },
        {
          text: "Dynamic",
          value: "dynamic",
        },
        {
          text: "Fixed value",
          value: "fixed",
        },
      ]
    },
    isNew() {
      if (!this.item || !this.item.id) {
        return true;
      }
      return false;
    },
    dataHasChanged() {
      if (!this.item) {
        return true;
      }
      const { key, defaultValue, position, inputType } = this.item;
      return this.inputType != inputType || this.key != key || this.defaultValue != defaultValue || this.position != position;
    }
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.setValue(val);
        if (val.id) {
          this.$forceCompute("isNew");
        }
      }
    }
  },
  created() {
    this.setValue(this.item);
  },
  methods: {
    ...mapMutations({
      OpenError: "OpenError",
    }),
    ...mapActions("integration", {
      dispatchCreateInput: "createInput",
      dispatchUpdateInput: "updateInput",
      dispatchDeleteInput: "deleteInput",
    }),
    updateProp(key) {
      if (this.isNew) {
        this.item[key] = this[key];
      }
    },
    setValue(val) {
      this.key = val.key;
      this.defaultValue = val.defaultValue;
      this.position = val.position;
      this.inputType = val.inputType || "manual";
    },
    async deleteMe() {
      if (this.readonly) {
        return;
      }
      if (!this.isNew) {
        try {
          this.deleting = true;
          await this.dispatchDeleteInput({
            id: this.integrationId,
            endpointId: this.endpointId,
            inputId: this.item.id
          })
        } catch (error) {
          this.OpenError({
            message: `Cannot delete input due to [${error}]`
          });
          this.deleting = false;
          return;
        }
      }
      this.deleting = false;
      this.$emit("delete", { item: this.item, index: this.index });
    },
    async saveMe() {
      if (this.readonly || !this.withAction || (!this.dataHasChanged && !this.isNew)) {
        return;
      }
      let submitFunc = this.dispatchCreateInput;
      let params = {
        id: this.integrationId,
        endpointId: this.endpointId,
        data: {
          key: this.key,
          defaultValue: this.defaultValue,
          position: this.position,
          inputType: this.inputType,
        }
      };
      if (!this.isNew) {
        submitFunc = this.dispatchUpdateInput;
        params.inputId = this.item.id;
      }
      let outputData = {};
      try {
        this.saving = true
        outputData = await submitFunc(params);
      } catch (err) {
        this.OpenError({
          message: `Cannot save input due to [${err}]`
        });
        this.saving = false;
        return;
      }

      this.saving = false;

      const data = {
        ...this.item,
        ...outputData,
        key: this.key,
        defaultValue: this.defaultValue,
        position: this.position,
      };

      this.$emit("save", data, this.index);
    },
  }
};
</script>

<style></style>
